html {
  height: 100%;
}
* {
  box-sizing: border-box;
}
html,
body {
  height: 100%;
  overflow: hidden;
}
body {
  background-size: cover;
  display: flex;
  margin: 0;
  color: #ffd5eb;
  font-family: "Lexend", sans-serif;
}
h1,
h2,
h3 {
  font-family: "Gilda Display", serif;
}
button {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}
@media (max-width: 679px) {
  .lge-only {
    display: none;
  }
}
@media (min-width: 680px) {
  .mobile-only {
    display: none;
  }
}
.hidden {
  display: none;
  visibility: hidden;
}
.active .hidden {
  display: block;
  visibility: visible;
}
.frame .header {
  padding: 10px 10px 0;
  color: #fff;
  font-family: "Gilda Display", serif;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
}
@media (min-width: 680px) {
  .frame .header {
    padding: 20px 20px 0 20px;
  }
}
.frame .header span.title {
  opacity: 0.5;
  cursor: pointer;
  transition: 0.3s;
}
.frame .header span.title:hover {
  opacity: 1;
  font-size: 17px;
}
.frame .header .contact-links {
  float: right;
  display: flex;
}
.frame .header .contact-links .link {
  width: 20px;
  height: 20px;
  display: inline-block;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  margin-left: 20px;
  opacity: 0.5;
  transition: 0.2s;
}
.frame .header .contact-links .link.linkedin {
  background-image: url(../assets/linkedin-white.svg);
  background-size: 90%;
}
.frame .header .contact-links .link.github {
  background-image: url(../assets/github-white.svg);
  background-size: 90%;
}
.frame .header .contact-links .link.codepen {
  background-image: url(../assets/codepen-white.svg);
}
.frame .header .contact-links .link.email {
  background-image: url(../assets/envelope.svg);
}
.frame .header .contact-links .link:hover {
  width: 22px;
  height: 22px;
  opacity: 1;
}
.frame .header .contact-links .link.in-front {
  z-index: 1000;
}
.frame .header .nav-menu {
  color: #fff;
  font-size: 30px;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  right: 0;
  background-color: #574ae2;
  opacity: 0.8;
  transition: 0.3s;
  padding: 50px;
  height: 100vh;
}
.frame .header .nav-menu button {
  color: #fff;
  text-decoration: none;
  margin-bottom: 30px;
  transition: 0.3s;
  text-align: left;
}
.frame .header .nav-menu button:hover {
  transform: translate(10px);
}
.frame .page-map-wrapper .page-map {
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: overlay;
  overflow-x: hidden;
  scroll-snap-type: y mandatory;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  transform: rotate(-90deg) translate(-100%);
  transform-origin: top left;
  background-color: #000;
  height: 100vw;
  width: 100vh;
}
@media (min-width: 680px) {
  .frame .page-map-wrapper .page-map {
    padding: 0;
  }
}
.frame .page-map-wrapper .page-map .page {
  scroll-snap-align: start;
  flex-shrink: 0;
  width: 100vw;
  height: 100vh;
  position: relative;
  overflow-y: scroll;
  cursor: pointer;
  transition: 0.3s;
  transform: rotate(90deg);
  margin-bottom: calc(100vw - 100vh);
  padding-bottom: 30px;
}
@media (min-width: 680px) {
  .frame .page-map-wrapper .page-map .page {
    padding-bottom: 0;
  }
}
.frame .page-map-wrapper .page-map .page-text {
  background-color: #574ae2;
  background-image: url(../assets/asfalt-light.png);
  background-attachment: local;
}
.frame .page-map-wrapper .page-map .imagePage {
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}
@media (min-width: 680px) {
  .frame .page-map-wrapper .page-map .imagePage {
    background-attachment: fixed;
    border: 5px #574ae2 solid;
  }
}
.frame .page-map-wrapper .page-map::-webkit-scrollbar {
  display: none;
}
@media (min-width: 680px) {
  .frame .navigation {
    cursor: pointer;
    color: #fff;
    text-align: center;
    font-size: 30px;
  }
  .frame .navigation .nav-left,
  .frame .navigation .nav-right {
    position: absolute;
    width: 75px;
    height: 80vh;
    line-height: 80vh;
    top: 60px;
    transition: 0.3s;
    opacity: 0.5;
  }
  .frame .navigation .nav-left:hover,
  .frame .navigation .nav-right:hover {
    opacity: 1;
  }
  .frame .navigation .nav-left {
    left: 0;
  }
  .frame .navigation .nav-left:hover {
    transform: translate(-10px);
  }
  .frame .navigation .nav-left.hide {
    transform: translate(-100px);
  }
  .frame .navigation .nav-right {
    right: 0;
  }
  .frame .navigation .nav-right.hide {
    transform: translate(100px);
  }
  .frame .navigation .nav-right.landing-nav {
    animation: shake 3s;
    animation-iteration-count: infinite;
  }
  .frame .navigation .nav-right:hover {
    animation: none;
    transform: translate(10px);
  }
}
@keyframes shake {
  0% {
    transform: translateX(0px);
    opacity: 0.5;
  }
  5% {
    transform: translateX(-20px) rotate(-5deg);
  }
  10% {
    transform: translateX(15px) rotate(3deg);
    opacity: 0.8;
  }
  15% {
    transform: translateX(-10px) rotate(-3deg);
  }
  20% {
    transform: translateX(5px) rotate(2deg);
    opacity: 0.8;
  }
  25% {
    transform: translateX(-2px) rotate(-1deg);
  }
  32% {
    transform: translateX(0px);
    opacity: 0.5;
  }
  100% {
    transform: translateX(0px);
    opacity: 0.5;
  }
}
.frame .back-to-start {
  position: absolute;
  right: 20px;
  font-size: 18px;
  transition: 0.3s;
  top: calc(50vh - 30px);
  opacity: 0.5;
}
.frame .back-to-start a {
  color: #fff;
}
.frame .back-to-start:hover {
  transform: translate(-20px);
  opacity: 1;
}
.page {
  transition: 0.5s;
}
.page.active,
.page:hover {
  opacity: 1;
}
.page h1,
.page h2,
.page h3 {
  font-family: "Gilda Display", serif;
}
.page h1 {
  text-align: center;
  font-size: 4rem;
}
@media (min-width: 680px) {
  .page h1 {
    font-size: 5rem;
  }
}
.page h2 {
  font-size: 2rem;
}
@media (min-width: 680px) {
  .page h2 {
    font-size: 3rem;
  }
}
.page h3 {
  font-size: 1.5rem;
}
.page p {
  font-family: "Lexend", sans-serif;
  text-align: left;
}
.page a {
  color: #ffd5eb;
}
.page img {
  border-radius: 5px;
  box-shadow: 1px 3px 8px 0px #00000040;
}
.page .card-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 15px;
}
@media (min-width: 680px) {
  .page .card-container {
    width: 49%;
  }
}
.page .card {
  border: 1px #ffd5eb dashed;
  border-radius: 5px;
  padding: 10px 15px;
}
.page .tabComponent {
  transition: 0.3s;
  max-width: 100%;
  overflow: scroll;
  padding: 10px;
}
.page .tabComponent .tabs {
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
  transition: 0.3s;
}
.page .tabComponent .tabs .tab {
  transition: 0.3s;
  box-shadow: none;
  padding: 10px !important;
}
.page .tabComponent .tabs .tab:hover {
  box-shadow: 1px 3px 8px 0px #00000040;
  transform: rotate(5deg);
}
.page .tabComponent .tabs.showing-text .tab {
  opacity: 0.3;
  transition: 0.3s;
}
.page .tabComponent .tabs.showing-text .tab:hover {
  opacity: 0.6;
  box-shadow: 1px 3px 8px 0px #00000040;
}
.page .tabComponent .tabs.showing-text .tab.active {
  opacity: 1;
}
.page .tabComponent .content {
  transition: 1s;
  max-height: 0;
  transition: max-height 0.8s ease-out;
  overflow: hidden;
}
.page .tabComponent .content.showing-text {
  padding: 10px 0;
  border-radius: 5px;
  max-height: 500px;
  transition: max-height 0.8s ease-in;
}
.page.landing {
  background-size: cover;
  background-position: 35% 20%;
  color: #fff;
  margin-top: 500px;
}
@media (min-width: 680px) {
  .page.landing {
    background-attachment: fixed;
  }
}
@media (max-width: 679px) {
  .page.landing .article {
    padding: 10px 20px 20px;
    background-image: linear-gradient(#ff000000, #00000080);
    width: 100%;
    position: absolute;
    bottom: 0;
    padding: 65px 20px;
  }
}
@media (min-width: 680px) {
  .page.landing .article {
    max-width: 320px;
    margin: 50px 30px;
    padding: 30px;
    background: none;
    bottom: unset;
    font-size: 20px;
  }
}
.page.landing .article .landing-text p {
  text-align: center;
  margin-top: 30px;
}
.page.about .profile-image {
  border-radius: 50%;
  border: 1px #ffd5eb dashed;
  display: inline;
  float: right;
  width: 30%;
  padding: 10px;
  margin: 10px;
}
.page.skills .card p,
.page.skills .card ul {
  display: none;
  width: 0;
  opacity: 0;
  transition: 1s width step-end;
}
.page.skills .card:hover p,
.page.skills .card:hover ul {
  display: block;
  opacity: 1;
  width: 100%;
}
.page.projects .article .card {
  padding: 30px;
}
.page.projects .article .appSection {
  display: flex;
  margin-bottom: 50px;
  flex-wrap: nowrap;
  gap: 10px;
}
@media (max-width: 400px) {
  .page.projects .article .appSection {
    flex-wrap: wrap;
  }
}
.page.projects .article .appSection .appDesc {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 10px;
}
@media (min-width: 680px) {
  .page.projects .article .appSection .appDesc {
    flex-direction: row;
  }
}
.page.projects .article .appSection .appDesc .text {
  max-width: 500px;
}
.page.projects .article .appSection .appDesc a {
  align-self: center;
}
.page.projects .article .appSection .appDesc a #expo-qr {
  max-width: 150px;
  margin-bottom: 20px;
}
@media (min-width: 680px) {
  .page.projects .article .appSection .appDesc a #expo-qr {
    margin-left: 15px;
    max-width: 150px;
  }
}
.page.projects .article .appSection .mobile-image {
  position: relative;
  width: 230px;
  height: 100%;
  margin-bottom: 30px;
}
.page.projects .article .appSection .mobile-image::before {
  content: "";
  top: 0;
  right: calc(50% - 45px);
  position: absolute;
  display: block;
  height: 25px;
  width: 85px;
  background-color: #000;
  border-radius: 0 0 13px 13px;
  z-index: 99;
}
.page.projects .article .appSection .mobile-image .mobile-border {
  border: 7px solid black;
  border-radius: 30px;
}
.page.projects .article .appSection .mobile-image .mobile-border .video-react,
.page.projects .article .appSection .mobile-image .mobile-border .video-react-video,
.page.projects .article .appSection .mobile-image .mobile-border .video-react-poster {
  border-radius: 23px;
}
.page.projects .article .appSection.project-section {
  display: flex;
  flex-direction: column;
}
.page.projects .article .appSection.project-section .project-text {
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
}
.page.projects .article .appSection.project-section .project-media {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  gap: 15px;
  flex-wrap: wrap;
}
@media (min-width: 680px) {
  .page.projects .article .appSection.project-section .project-media {
    justify-content: space-around;
  }
}
.page.projects .article .appSection.project-section .project-media .media {
  width: 30%;
  min-width: 230px;
}
.page.projects .article .biancaVideos {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.page.projects .article .biancaVideos .videoEmbed {
  height: 180px;
  width: 300px;
}
@media (min-width: 440px) {
  .page.projects .article .biancaVideos .videoEmbed {
    height: 207px;
    width: 370px;
  }
}
@media (min-width: 440px) and (min-width: 560px) {
  .page.projects .article .biancaVideos .videoEmbed {
    height: 300px;
    width: 530px;
  }
}
@media (min-width: 440px) and (min-width: 560px) and (min-width: 900px) {
  .page.projects .article .biancaVideos .videoEmbed {
    height: 370px;
    width: 630px;
  }
}
.page.projects .article .biancaVideos iframe #root {
  border-radius: 5px;
  box-shadow: 1px 3px 8px 0px #00000040 !important;
}
.page .article {
  padding: 10px 20px 80px;
  margin: 30px auto 0;
}
@media (min-width: 680px) {
  .page .article {
    padding: 60px 100px;
    max-width: 1000px;
  }
}
.page .text-section {
  margin-bottom: 75px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 15px;
}
.page .work .work-tile-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 15px;
}
.page .work .work-tile-container .work-element {
  text-align: left;
}
